<template>
  <div class="page">
    <img class="background-top__size" src="@/assets/imgs/calc/index-top-background.png" >
    <div class="content-page">
      <slot name="topBox"></slot>
      <div class="center-box">
        <!-- <slot name="topBtn"></slot>
        <div class="content-box">
          <slot></slot>
        </div> -->
        <slot name="footerBtn"></slot>
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    name: "CalcPage"
  }
</script>

<style scoped>
.page {
}

.background-top__size {
  width: 100%;
}

.content-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  margin-top: -180px;
}

.center-box {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 80px;
}

.content-box {
  width: 100%;
  padding: 20px 12px 40px 12px;
}


</style>