<template>
  <div class="page">
    <calc-page>
      <template slot="topBox">
        <round-container>
          <div class="item-box">
            <div>
              <title-item text="间断时间"></title-item>
              <div class="time-box">
                <year-month-range-input
                  ref="rangendDate"
                ></year-month-range-input>
              </div>
            </div>
          </div>
          <van-divider />
        </round-container>
        <div class="desc-box">
          <div class="desc-title">间断时间说明：</div>
          <div class="desc">
            2004年12月31日以前不符合医疗保险视同缴费年限（包括1993年3月1日-2004年12月31日参加城镇企业职工基本养老保险个人参保年限）
          </div>
          <div class="desc">2005年1月1日以后，医疗保险实际缴费的间断年限</div>
        </div>
      </template>

      <template slot="footerBtn">
        <div class="btn-box" v-if="!isUpdate">
          <div class="btn-cancel add_width" @click="onBack">取消</div>
          <div class="btn-confirm add_width" @click="onFinish">确定</div>
        </div>

        <div class="btn-box" v-else>
          <div class="btn-cancel update_width" @click="onBack">取消</div>
          <div class="btn-del update_width" @click="onDel">删除</div>
          <div class="btn-confirm update_width" @click="onUpdate">确定</div>
        </div>
      </template>
    </calc-page>
  </div>
</template>

<script>
import { Divider, Dialog } from "vant";
import YearMonthRangeInput from "@/components/YearMonthRangeInput";
import RoundContainer from "@/views/calc/components/RoundContainer";
import CalcPage from "@/components/CalcPage";
import TitleItem from "@/views/calc/TitleItem";
import { mapGetters, mapMutations } from "vuex";
import { isTimeOverlapped,isTimeReversal } from "@/utils/time.js";
export default {
  components: {
    CalcPage,
    TitleItem,
    RoundContainer,
    [Divider.name]: Divider,
    YearMonthRangeInput,
  },
  data() {
    return {
      show: false,
      isUpdate: false,
      clickItem: "0",
      level: "",
      data: null,
    };
  },
  computed: {
    list: {
      get() {
        return this.getMedicalPayInFull().medicalGapRange;
      },
      set(val) {
        this.setMedicalPayInFull({ medicalGapRange: val });
      },
    },
  },
  mounted() {
    const { item, isUpdate } = this.$route.query;
    if (isUpdate != undefined) {
      this.isUpdate = true;
      this.onInit(JSON.parse(item));
    } else {
      this.data = {
        idx: new Date().getTime(),
        begDate: "",
        endDate: "",
      };
    }
  },
  methods: {
    onInit(item) {
      this.data = item;
      const list = [];
      list.push(item.begDate);
      list.push(item.endDate);
      this.$refs.rangendDate.setValue(list);
    },
    onDel() {
      let index = this.list.findIndex((item) => {
        if (item.idx === this.data.idx) {
          return true;
        }
      });

      this.list.splice(index, 1);
      this.list = this.list;

      this.$router.go(-1);
    },
    onUpdate() {
      this.data.begDate = this.$refs.rangendDate.getValue()[0];
      this.data.endDate = this.$refs.rangendDate.getValue()[1];

      const itemEnd = new Date(this.data.endDate);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth()+1;
      if (itemEnd > new Date()) {
        Dialog.alert({
          title: "间断时间有误",
          message: "间断时间末尾日期不能大于当前时间：" + currentYear + "/" + currentMonth + "，请重新输入"
        });
        return;
      }

      const timeErr = isTimeReversal(this.data);
      if (timeErr) {
        Dialog.alert({
          title: "日期格式不正确",
          message: "起始时间不能大于末尾时间"
        })
        return;
      }

      const timeOverlapped = isTimeOverlapped(this.list, this.data);
      if (timeOverlapped != null) {
        Dialog.alert({
          title: "时间段重合",
          message:
            "输入的时间段，和已经存在的时间段" +
            timeOverlapped.begDate +
            "-" +
            timeOverlapped.endDate +
            "重合, 请重新输入",
        });
        return;
      }

      this.list.findIndex((item) => {
        if (item.idx === this.data.idx) {
          item.begDate = this.data.begDate;
          item.endDate = this.data.endDate;
          console.log(this.data);
          return true;
        }
      });

      this.list = this.list;
      this.$router.go(-1);
    },
    onFinish() {
      this.data.begDate = this.$refs.rangendDate.getValue()[0];
      this.data.endDate = this.$refs.rangendDate.getValue()[1];
      console.log(this.data.begDate);

      if (this.data.begDate == "") {
        this.$toast.fail("请填写开始时间");
        return;
      }

      if (this.data.endDate == "") {
        this.$toast.fail("请填写结束时间");
        return;
      }

      const itemEnd = new Date(this.data.endDate);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth()+1;
      if (itemEnd > new Date()) {
        Dialog.alert({
          title: "间断时间有误",
          message: "间断时间末尾日期不能大于当前时间：" + currentYear + "/" + currentMonth + "，请重新输入"
        });
        return;
      }

      const timeErr = isTimeReversal(this.data);
      if (timeErr) {
        Dialog.alert({
          title: "日期格式不正确",
          message: "起始时间不能大于末尾时间"
        })
        return;
      }
      const timeOverlapped = isTimeOverlapped(this.list, this.data);
      if (timeOverlapped != null) {
        Dialog.alert({
          title: "时间段重合",
          message:
            "输入的时间段，和已经存在的时间段" +
            timeOverlapped.begDate +
            "-" +
            timeOverlapped.endDate +
            "重合, 请重新输入",
        });

        return;
      }

      this.list.push(this.data);
      this.list = this.list;
      this.$router.go(-1);
    },
    onBack() {
      this.$router.go(-1);
    },
    ...mapGetters("calc", ["getMedicalPayInFull"]),
    ...mapMutations("calc", ["setMedicalPayInFull"]),
  },
};
</script>

<style scoped>
.page >>> .van-divider {
  margin: 0;
}

.item-box {
  /* display: flex;
  align-items: flex-end; */
  padding: 24px 0 12px 0;
}

.item-box >>> .van-cell {
  padding: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.row-box {
  display: flex;
  justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-field__control {
  width: 200px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.time-box {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  color: #333333;
  margin-top: 8px;
}

.time-box div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-box {
  position: fixed;
  bottom: 10px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  display: flex;
  justify-content: space-between;
  color: white;
}

.add_width {
  width: 172px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update_width {
  width: 115px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-cancel {
  background: #d2d3d4;
  border-radius: 8px 0px 0px 8px;
}

.btn-del {
  background: #f98c39;
}

.btn-confirm {
  background: #658CF1;
  border-radius: 0px 8px 8px 0px;
}

.action-box {
  padding: 20px 6px 20px 36px;
  display: flex;
  flex-wrap: wrap;
}

.action-item__box {
  width: 88px;
  height: 38px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 28px 0;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #333333;
}

.clickBk {
  background: #658CF1;
  color: #ffffff;
}

.desc-box {
  margin-top: 80px;
  color: #658CF1;
  font-size: 12px;
}

.desc-title {
  font-size: 14px;
  font-weight: bold;
}

.desc {
  margin-top: 12px;
  margin-left: 10px;
  position: relative;
}

.desc::before {
  position: absolute;
  left: -10px;
  top: 6px;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #658CF1;
}
</style>